import * as Configuration from './configuration'
import * as Data from './data'
import * as I18n from '@rushplay/i18n'
import * as Icons from './icons'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as SignUp from './sign-up'
import * as ThemeUi from 'theme-ui'

import {NodapayObserver} from './nodapay-observer'
import {PwclickObserver} from './pwclick-observer'
import Querystring from 'querystring'

/**
 * Handles callback for payment-methods that redirect windows
 * @component Callback
 * @returns {ReactNode|null} only actually renders something for some specific cases
 */
export function Callback() {
  const translate = I18n.useTranslate()
  const {callback} = ReactRouter.useParams()
  const dispatch = ReactRedux.useDispatch()
  const location = ReactRouter.useLocation()
  const [showMessage, setShowMessage] = React.useState(false)

  // This is data that we want to forward to host-applications
  const forwardData = ReactRedux.useSelector(Data.getForwardData)
  const origin = ReactRedux.useSelector(Configuration.getOrigin)
  const parentOrigin = ReactRedux.useSelector(Configuration.getParentOrigin)
  // Trustly appends sessiontoken and errors as queries so this captures them
  const responseQuery = Querystring.parse(R.drop(1, location.search))

  React.useEffect(() => {
    switch (callback) {
      case 'transaction-cancel': {
        // If the processing-step was opened in a new browser/tab close the window
        if (window.top === window.self) {
          window.close()
        }
        dispatch(Data.transactionCancel(forwardData))
        break
      }

      case 'transaction-failure': {
        dispatch(Data.transactionFailure(forwardData))
        // If the processing-step was opened in a new browser/tab close the window
        if (window.top === window.self) {
          window.close()
        }
        break
      }

      case 'transaction-success': {
        dispatch(Data.transactionSuccess(forwardData))
        // If the processing-step was opened in a new browser/tab close the window
        if (window.top === window.self) {
          window.close()
        }
        break
      }

      case 'transaction-pending': {
        localStorage.setItem('pendingPiqTransactionMessage', 'true')

        // If the processing-step was opened in a new browser/tab close the window
        if (window.top === window.self) {
          window.opener = window
          const win = window.open('', '_self')
          win.close()
          top.close()
        }
        break
      }

      case 'trustly': {
        // This case is handled inside the component
        break
      }

      case 'transfer_world': {
        localStorage.setItem('pendingTransferWorldTransactionMessage', 'true')
        window.close()
        break
      }

      case 'brite': {
        break
      }

      case 'projs': {
        // If projs was opened in another window it'll try to redirect back here in that window
        // So we simply close it to get the user back to where they initiated the transaction
        window.close()
        break
      }

      case 'payust': {
        window.close()
        break
      }

      case 'luqapay': {
        break
      }

      case 'megapay': {
        break
      }

      case 'approvely': {
        break
      }

      case 'pwclick': {
        break
      }

      case 'nodapay': {
        break
      }

      case 'zimpler': {
        break
      }

      default: {
        // eslint-disable-next-line no-console
        console.warn('Unknown callback type:', callback)
      }
    }
  }, [callback, dispatch, forwardData])

  React.useEffect(() => {
    const id = setTimeout(() => {
      setShowMessage(true)
    }, 3000)

    return () => {
      clearTimeout(id)
    }
  }, [])

  if (R.includes(callback, ['brite', 'trustly', 'zimpler'])) {
    return (
      <SignUp.SignUpObserver
        query={responseQuery}
        origin={origin}
        target="parent"
      />
    )
  }

  if (R.includes(callback, ['pwclick'])) {
    return <PwclickObserver query={responseQuery} />
  }

  if (R.includes(callback, ['nodapay'])) {
    return <NodapayObserver query={responseQuery} />
  }

  if (!showMessage) {
    return null
  }

  return (
    <ThemeUi.Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        px: '20px',
        textAlign: 'center',
      }}
    >
      <ThemeUi.Box sx={{mt: '50px', fontSize: '50px', lineHeight: '50px'}}>
        <Icons.Info />
      </ThemeUi.Box>

      <ThemeUi.Box
        sx={{
          borderBottom: '1px solid #e4e4e4',
          my: '30px',
        }}
      />
      <ThemeUi.Box
        sx={{
          fontSize: '16px',
        }}
      >
        {translate('transaction.callback.close-window.message')}
      </ThemeUi.Box>
      <ThemeUi.Box sx={{mt: '30px'}}>
        <ThemeUi.Button
          sx={{
            fontSize: '16px',
            px: '16px',
            height: '40px',
          }}
          onClick={() => {
            window.open(parentOrigin, '_self')
          }}
        >
          {translate('transaction.callback.button')}
        </ThemeUi.Button>
      </ThemeUi.Box>
    </ThemeUi.Box>
  )
}

// for @loadable/components
export default Callback

I18n.Loader.preload(
  ['transaction.callback.close-window.message', 'transaction.callback.button'],
  Callback
)
